<template>
  <footer class="footer_content" id="contentUs">
    <div class="footer_box">
      <div class="footer_detail">
        <div class="footer_text">
          <div>
            <h1>ຂໍ້ຕົກລົງ ແລະ ຖະແຫຼງການ</h1>
            <p style="cursor: pointer" @click="goTo(isSupport ? 'https://apph5.unionlao.com/app-n-terms.html' : 'https://h5.unionlao.com/TermsConditions')">
              ຂໍ້ກຳນົດຂອງຜູ້ໃຊ້
              {{ isSupport }}
            </p>
            <p style="cursor: pointer" @click="goTo(isSupport ? 'https://apph5.unionlao.com/app-n-privacy.html' : 'https://h5.unionlao.com/PrivacyPolicy')">
              ຂໍ້ຕົກລົງຄວາມເປັນສ່ວນຕົວ
            </p>
            <p v-if="isSupport" style="cursor: pointer" @click="goTo('https://www.unionlao.com/loanAgreement.html')">ສັນຍາເງິນກູ້</p>
          </div>
          <div>
            <h1>ກະລຸນາຕິດຕໍ່ຫາພວກເຮົາ</h1>
            <p>TEL: 021411555</p>
            <p>Whatsapp : 020 55511163</p>
            <p>email: unionlao.m@gmail.com</p>
            <p>Facebook: Unionlao</p>
            <p>Tiktok: Unionlao</p>
            <p>Youtube: Unionlao</p>
          </div>
        </div>
        <div class="footer_copyRight">© UNION LAO Non Deposit Taking Microfinance Institution Co., LTD</div>
      </div>
    </div>
    <div class="footer_logo">
      <img src="@/assets/logo.png" alt="" />
    </div>
  </footer>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'FFooter',
  props: {
    isSupport: Boolean
  },
  setup(props, ctx) {
    const goPost = () => {
      const doc = document.documentElement || document.body;
      let distance = doc.scrollTop; //获得当前高度
      (function jump() {
        let step = distance / 5; //每步的距离
        if (distance > 0) {
          distance -= step;
          distance = distance < 1 ? 0 : distance;
          window.scrollTo(0, distance);
          window.requestAnimationFrame(jump);
        }
      })();
    };
    const goTo = href => {
      // window.location.href = href;
      window.open(href, '_blank');
    };
    return {
      goPost,
      goTo
    };
  }
};
</script>
<style lang="scss" scoped>
.footer_content {
  width: 100%;
  min-width: 1280px;
  position: relative;
  margin-top: 78px;
  .footer_logo {
    position: absolute;
    bottom: 34px;
    left: 80px;
    width: 190px;
    height: 49px;
    img {
      width: 190px;
      height: 49px;
    }
  }
  .color_bar {
    width: 100%;
    height: 131px;
    background: linear-gradient(90.2deg, rgba(255, 92, 87, 1) 0.15%, rgba(255, 197, 133, 1) 100.06%);
    padding-top: 30px;
    h2 {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 32px;
    }
    h6 {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .footer_box {
    width: 100%;
    height: 536px;
    background: rgba(7, 15, 36, 1);
    .footer_detail {
      width: 1020px;
      margin: 0 auto;
      padding-top: 30px;

      .footer_text {
        width: 80%;
        margin-top: 90px;
        display: flex;
        justify-content: space-between;
        h1 {
          color: rgba(255, 255, 255, 1);
          font-family: Saysettha OT;
          font-size: 36px;
          line-height: 30px;
          margin-bottom: 16px;
        }
        p {
          color: rgba(255, 255, 255, 1);
          font-family: Saysettha OT;
          font-size: 18px;
          line-height: 50px;
          opacity: 0.6;
        }
        .contact {
          margin-top: 8px;
          span {
            display: inline-block;
            width: 28px;
            height: 24px;
            vertical-align: middle;
            margin-right: 10px;
            img {
              width: 28px;
              height: 24px;
            }
          }
          p {
            display: inline-block;
            color: rgba(0, 0, 0, 1);
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
      .footer_copyRight {
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: Alibaba PuHuiTi;
        font-size: 14px;
        margin-top: 32px;
        opacity: 0.6;
      }
    }
  }
}
</style>
