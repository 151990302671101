<template>
  <header
    class="header_container fixed"
    :class="{
      headBGColor: scrollPosition > 370 || currentPath.indexOf('/terminos-y-condiciones') != -1 || currentPath.indexOf('/politica-de-privacidad') != -1
    }"
  >
    <div class="header_box" ref="headNav">
      <div class="logoBox" @click="goTo({ label: 'Home', name: 'Home', to: '/home' })">
        <img alt="" src="../assets/logo.png" />
      </div>
      <div style="width: 60%; display: flex; justify-content: space-between; align-items: center">
        <ul class="head_tab" style="width: 40%">
          <li
            v-for="(item, index) in navs"
            :key="index"
            class="ml10"
            :class="{
              currentActive: item.name === currentTab
            }"
            @click="goTo(item)"
          >
            <span>{{ item.label }}</span>
          </li>
        </ul>
        <!-- <div
          class="login_btn"
          :class="{
            loginActive: currentTab === 'Login',
          }"
          @click="goTo({ label: 'Login', name: 'Login', to: '/login' })"
        >
          ລົງທະບຽນ / ເຂົ້າສູ່ລະບົບ
        </div> -->
      </div>
    </div>
  </header>
</template>

<script>
import { ref, watch, onMounted, inject, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'FNav',
  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    isSupport: Boolean
  },
  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    const routeName = ref('');
    const currentPath = ref('');
    routeName.value = route.name;

    const currentTab = ref('Home');

    const setNavFixed = inject('setNavFixed');

    const scrollPosition = ref(null);
    const updateScroll = () => {
      scrollPosition.value = window.scrollY;
    };
    onMounted(() => {
      window.addEventListener('scroll', updateScroll);
      currentPath.value = route.fullPath;
    });

    const navs = ref([
      {
        label: 'ໜ້າທຳອິດ',
        name: 'Home',
        to: '/home'
      },
      {
        label: 'ຜະລິດຕະພັນ',
        name: 'Product',
        to: '/product'
      }
      // {
      //   label: "ບັນຫາທົ່ວໄປທີ່ພົບເຫັນ",
      //   name: "About",
      //   to: "/about",
      // },
    ]);
    watch(
      () => router.currentRoute.value,
      (_new, _old) => {
        console.log(_new);
        console.log(_old);
        routeName.value = _new?.name;
        if (!_old) {
          currentTab.value = 'Home';
        }
        if (_old && _new.name !== _old.name && _new.name !== 'Home') {
          currentTab.value = _new ? _new.name : sessionStorage.getItem('CURRENT_TAB');
        }

        // console.log(_new.name);
        // currentTab.value = _new
        //   ? _new.name
        //   : sessionStorage.getItem("CURRENT_TAB");
        currentPath.value = _new?.fullPath;
        console.log(currentTab.value);
        // navs.forEach((item) => {
        //   // console.log(item);
        //   if (item.name === _new) {
        //     currentTab.value = item.label;
        //     console.log(currentTab.value);
        //   }
        // });
        props.fixed && setNavFixed(false);
        if (_new.name === 'Support') {
          navs.value.push({
            label: 'ສັນຍາເງິນກູ້',
            name: 'Loan',
            to: ''
          });
        } else {
          navs.value = navs.value.filter(item => item.to);
        }
      },
      {
        immediate: true
      }
    );
    // const changeTab = (item) => {
    //   currentTab.value = item.label;
    // };
    const goTo = item => {
      if (!item.to) {
        window.open('https://www.unionlao.com/loanAgreement.html', '_blank');
        return;
      }
      currentTab.value = item.name;
      console.log(currentTab.value);
      sessionStorage.setItem('CURRENT_TAB', currentTab.value);
      if (route.fullPath !== item.to) {
        router.push({ path: item.to });
      }
      if (item.name === 'Home') {
        router.push({ path: item.to });
      }

      if (item.name === 'Work') {
        let toElement = document.getElementById('mainContent');
        console.log(toElement);
        if (toElement) {
          setTimeout(() => {
            toElement.scrollIntoView({
              behavior: 'smooth', // 平滑过渡
              block: 'start',
              inline: 'start'
            });
          }, 100);
        }
      }
      if (item.name === 'Contact') {
        let toElement = document.getElementById('contentUs');
        if (toElement) {
          setTimeout(() => {
            toElement.scrollIntoView({
              behavior: 'smooth', // 平滑过渡
              block: 'start',
              inline: 'start'
            });
          }, 100);
        }
      }
    };
    function onChange(active) {}

    return {
      routeName,
      currentPath,
      navs,
      currentTab,
      // changeTab,
      scrollPosition,
      goTo
    };
  }
};
</script>
<style lang="scss" scoped>
.headBGColor {
  background: linear-gradient(180deg, rgba(204, 231, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.header_container {
  transition: padding 0.4s ease;
  will-change: padding;
  position: relative;
  z-index: 100;
  width: 100%;
  min-width: 1280px;
  height: 60px;
  line-height: 60px;
  padding-top: 33px;
  .header_box {
    width: 1280px;
    height: 60px;
    overflow: hidden;
    align-items: center;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  // background-color: #fff;
  // .padding-top(30px);
  // .padding-left(36px);
  // .padding-right(36px);
  // @media @max768 {
  //   .padding-top(20px);
  //   .padding-left(26px);
  //   .padding-right(26px);
  // }
  .logoBox {
    width: 190px;
    height: 49px;
    line-height: 49px;
    cursor: pointer;
    img {
      width: 190px;
      height: 49px;
    }
  }
  .head_tab {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  .login_btn {
    width: 190px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid rgba(11, 50, 141, 1);
    box-sizing: border-box;
    color: rgba(11, 50, 141, 1);
    font-family: Saysettha OT;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background: rgba(11, 50, 141, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  .loginActive {
    background: rgba(11, 50, 141, 1);
    color: rgba(255, 255, 255, 1);
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
  }
  span {
    transition: 0.2s color;
    color: rgba(159, 159, 159, 1);
    font-family: Saysettha OT;
    font-size: 18px;
    &:hover {
      color: rgba(11, 50, 141, 1);
      font-family: Saysettha OT;
      font-size: 18px;
    }
  }
  .currentActive {
    span {
      // display: inline-block;
      // // width: 78px;
      // // height: 35px;
      // padding: 0px 10px;
      // text-align: center;
      // line-height: 35px;
      // border-radius: 27px;
      // background: rgba(255, 255, 255, 1);
      color: rgba(11, 50, 141, 1);
      font-family: Saysettha OT;
      font-size: 18px;
    }
  }
  &.white {
    // background-color: #000;
  }
  ::v-deep(.router-link-active) {
    &:hover {
      // display: inline-block;
      // width: 78px;
      // height: 35px;
      // text-align: center;
      // line-height: 35px;
      // border-radius: 27px;
      // background: rgba(255, 255, 255, 1);
      // color: rgba(255, 110, 96, 1);
      font-family: Roboto;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
.list {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  &:hover {
    font-style: italic;
  }
}
</style>
