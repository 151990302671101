<template>
  <div class="body">
    <Header :isSupport="routeName == 'Support'" />
    <router-view v-if="isRouterAlive" />
    <Footer :isSupport="routeName == 'Support'" />
    <div class="back_toTop" v-if="btnFlag" @click="backTop">
      <img src="@/assets/images/home/back_toTop.png" alt="" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { onBeforeMount, ref } from '@vue/runtime-core';
import { onMounted, provide, nextTick, watch, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
// 刷新
const onReload = () => {
  const isRouterAlive = ref(true);
  const reload = async () => {
    isRouterAlive.value = false;
    await nextTick();
    isRouterAlive.value = true;
  };
  return { isRouterAlive, reload };
};
export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const store = useStore();
    const state = ref('pc');
    const routeName = ref('');
    const route = useRoute();
    watch(
      () => route.name,
      _new => {
        routeName.value = _new;
      },
      {
        immediate: true
      }
    );
    const { isRouterAlive, reload } = onReload();
    const btnFlag = ref(false);
    const backTop = () => {
      let top = document.documentElement.scrollTop; //获取点击时页面的滚动条纵坐标位置
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top -= 50; //一次减50往上滑动
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 5); //定时调用函数使其更顺滑
    };
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 100) {
        btnFlag.value = true;
      } else {
        btnFlag.value = false;
      }
      //往下滑超过20则显示 否则则不显示按钮
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    }); //监听滚动事件
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    }); //离开页面时移除监听事件

    return {
      state,
      isRouterAlive,
      btnFlag,
      backTop,
      routeName
    };
  }
};
</script>

<style lang="scss">
.body {
  min-height: 100vh;
  transition: background-color 0.3s linear;
  .back_toTop {
    position: fixed;
    right: 30px;
    bottom: 100px;
    width: 69px;
    height: 69px;
    cursor: pointer;
    img {
      width: 69px;
      height: 69px;
    }
  }
}
* {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
</style>
