import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/product/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/politica-de-privacidad',
    name: 'Politica-de-privacidad',
    component: () => import('@/views/privacy_agreement.vue')
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Terminos-y-condiciones',
    component: () => import('@/views/registration_agreement.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/support/index.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      el: 'body',
      top: 0
    };
  }
});

router.afterEach(to => {
  // iView.LoadingBar.finish()
  window.scrollTo(0, 0);
  // NProgress.done()
});

export default router;
